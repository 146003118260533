import React from 'react';
import { Button, Box, Text, useDisclosure } from '@chakra-ui/react';

import WithAnimation from '@/components/Common/WithAnimation';
import Content from './Content';
import useLang from '@/hooks/useLang';

import { BUTTON_PROPS } from '@/constants/colors';
import txt from './locales';

function LoveStory() {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const lang = useLang();

  return (
    <Box bgColor="bgPrimary">
      <WithAnimation>
        <Box textAlign="center" padding="0 32px 32px 32px">
          <Text
            fontWeight="light"
            color="mainColorText"
            fontSize="md"
            margin="0"
            dangerouslySetInnerHTML={{ __html: txt.desc[lang] }} 
          />
          <Button
            bgColor="bgAlternative"
            color="white"
            size="sm"
            fontSize="small"
            fontWeight="normal"
            fontFamily="body"
            margin="16px 0 0 0"
            letterSpacing="1px"
            textTransform="uppercase"
            onClick={onOpen}
            {...BUTTON_PROPS}
          >
            {txt.textButton[lang]}
          </Button>
        </Box>
      </WithAnimation>
      <Content onClose={onClose} visible={isOpen} />
    </Box >
  );
}

export default LoveStory;