import Img1 from './assets/1.jpg';
import Img2 from './assets/2.jpg';
import Img3 from './assets/3.jpg';
import Img4 from './assets/4.jpg';
import Img5 from './assets/5.jpg';
import Img6 from './assets/6.jpg';
import Img7 from './assets/7.jpg';
import Img8 from './assets/8.jpg';

export default [
  {
    img: Img1,
    title: 'April 2020',
    content: `We were a group of friends traveling to a town on the mid north coast of new south wales called Woolgoolga, it was during covid outbreak in Australia and that's the first time we met.`,
  },
  {
    img: Img2,
    title: 'September 2020',
    content: `We bought a car and decided to travel around Australia trying to visit every state to see the landscape of every side in this country.`,
  },
  {
    img: Img3,
    title: 'February 2021',
    content: `We start dating and continue our adventure to see new places, new faces and new experiences.`,
  },
  {
    img: Img4,
    title: 'November 2021',
    content: `Our trip stop in the Northern Territory after a long road trip through New South Wales, Victoria, South Australia and Queensland.`,
  },
  {
    img: Img5,
    title: 'January 2023',
    content: `Novi met Tedy’s family for the first time on Bali trips.`,
  },
  {
    img: Img6,
    title: 'June 2023',
    content: `Tedy met Novi’s Family for the first time and made a proposal at Disneyland, which is Novi's favorite place on earth. Tedy got down on one knee to a lifetime of joy and adventure together.`,
  },
  {
    img: Img7,
    title: 'January 2024',
    content: `Pre wedding and engagement.`,
  },
  {
    img: Img8,
    title: 'February 2024',
    content: `Wedding day, and now we invite you to share and celebrate the next chapter of our story. Also, we are looking forward to continuing our adventure together.`,
  },
];