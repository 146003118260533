import React from 'react';
import { arrayOf, object } from 'prop-types';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Box, Text, Image, AspectRatio } from '@chakra-ui/react';

import loveStories from './api/lovestory-data';
import 'swiper/css';
import 'swiper/css/pagination';
import './styles.css';

SwiperCore.use([Pagination]);

function LoveStorySwapable({ story, ...rest }) {
  return (
    <Swiper
      pagination={{
        dynamicBullets: true,
      }}
      autoHeight
      className="mySwiper"
    >
      {story.map((i) => {
        return (
          <SwiperSlide key={i.title}>
            <Box padding="16px" borderRadius="16px" bgColor="bgSecondary" {...rest}>
              {i.img && (
                <AspectRatio ratio={1 / 1}>
                  <Image src={i.img} maxWidth="100%" borderRadius="8px" />
                </AspectRatio>
              )}
              <Text
                marginTop="16px"
                marginLeft="4px"
                color="white"
                fontFamily="heading"
                fontSize="3xl"
                marginBottom="16px"
                dangerouslySetInnerHTML={{ __html: i.title }}
              />

              <Text
                color="white"
                fontSize="md"
                dangerouslySetInnerHTML={{ __html: i.content }}
                marginBottom="24px"
              />
            </Box>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}

LoveStorySwapable.propTypes = {
  story: arrayOf(object),
};

LoveStorySwapable.defaultProps = {
  story: loveStories,
};

export default LoveStorySwapable;